import { Autorenew as Refetch } from "@mui/icons-material";
import { Divider, IconButton, Stack } from "@mui/material";
import {
  CreateButton,
  CreateButtonProps,
  EditButton,
  EditButtonProps,
  DeleteButton,
  DeleteButtonProps,
  ActionButtonProps,
  ActionButton,
} from "components/ActionButtons";

export interface ToolbarActions {
  create?: CreateButtonProps;
  custom?: ActionButtonProps[];
}

export interface SelectableToolbarActions {
  edit?: EditButtonProps;
  delete?: DeleteButtonProps;
  custom?: ActionButtonProps[];
}

interface Props<T> {
  actions?: ToolbarActions;
  selectedActions?: SelectableToolbarActions;
  selected?: T[];
  onRefetch?: () => void;
}

export const ActionToolbar = <T,>({
  actions,
  selectedActions,
  selected,
  onRefetch,
}: Props<T>) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      spacing={2}
      p={2}
    >
      {onRefetch && (
        <IconButton color="primary" onClick={onRefetch}>
          <Refetch />
        </IconButton>
      )}
      <Stack flex={1} />
      {selected &&
        selectedActions?.custom?.map((props) => <ActionButton {...props} />)}

      {selected && selectedActions?.edit && (
        <EditButton {...selectedActions.edit} />
      )}
      {selected && selectedActions?.delete && (
        <DeleteButton {...selectedActions.delete} />
      )}
      {selected && actions && selectedActions && (
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ borderColor: "#CF903A", borderWidth: "0.5px" }}
        />
      )}

      {actions?.custom?.map((props) => (
        <ActionButton {...props} />
      ))}
      {actions?.create && <CreateButton {...actions.create} />}
    </Stack>
  );
};
