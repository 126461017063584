import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import ROUTES, { RouteProps } from "routes";
import sevrBlackLogo from "assets/images/sevr-black.png";

const Navigation: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { pathname } = useLocation();
  
  const onLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  };

  const MenuListItem = ({ path, Icon, titleKey }: RouteProps) => (
    <ListItem disablePadding key={path}>
      <ListItemButton onClick={() => navigate(path)}>
        {Icon && (
          <ListItemIcon sx={{ ml: 2 }}>
            <SvgIcon
              color={pathname === path ? "primary" : "secondary"}
              sx={{ fontSize: 31 }}
            >
              <Icon />
            </SvgIcon>
          </ListItemIcon>
        )}
        {titleKey && (
          <ListItemText
            primary={t(titleKey)}
            primaryTypographyProps={{
              sx: {
                fontWeight: "bold",
                whiteSpace: "nowrap",
                color: pathname === path ? "primary.main" : "secondary.main",
              },
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{ width: 240, flexShrink: 0 }}
      PaperProps={{
        style: { width: 240, backgroundColor: "#0a0e16", border: 0 },
      }}
    >
      <Box sx={{ textAlign: "center", my: 2 }}>
        <img
          src={sevrBlackLogo}
          alt="Sevr Logo"
          style={{ width: "80%", height: "auto" }}
        />
      </Box>
      <Divider />
      <Stack spacing={2} mt={2}>
        {ROUTES.filter((route) => route.onNav && !route.bottomStack).map(
          MenuListItem
        )}
      </Stack>
      <Stack
        sx={{
          padding: 3,
          mt: "auto",
          fontWeight: "bold",
        }}
      >
        {ROUTES.filter((route) => route.onNav && route.bottomStack).map(
          MenuListItem
        )}

        <ListItem disablePadding sx={{ color: "primary.main" }}>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon sx={{ ml: 2 }}>
              <SvgIcon sx={{ fontSize: 31 }} color="primary">
                <Logout />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={t("auth:logout")}
              primaryTypographyProps={{
                sx: {
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      </Stack>
    </Drawer>
  );
};

export default Navigation;
