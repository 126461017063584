import React, {FC, useEffect, useMemo, useState} from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SyncType } from "types";

interface Props {
  onChange: (syncType: SyncType, syncFromYear: number) => void;
}

const SyncConnectionsDataForm: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const [syncType, setSyncType] = useState<SyncType>(SyncType.DELTA);
  const [year, setYear] = useState<number>(2020);
  const currentYear = new Date().getFullYear();

  const years = useMemo(() =>
      Array.from(
        new Array(currentYear - 2000 + 1),
        (_val, index) => 2000 + index
      ),
    [currentYear]
  );

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setYear(Number(event.target.value));
  };

  useEffect(() => {
    onChange(syncType, year);
  }, [syncType, onChange, year]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncType(event.target.value as SyncType);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2" gutterBottom>
        {t("fortnox:fast_or_from_scratch")}
      </Typography>

      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Radio
              checked={syncType === SyncType.DELTA}
              onChange={handleRadioChange}
              value={SyncType.DELTA}
              name="sync-type"
              inputProps={{ "aria-label": "Delta Sync" }}
            />
          }
          label={t("fortnox:radio_delta")}
        />

        <FormControlLabel
          control={
            <Radio
              checked={syncType === SyncType.FULL}
              onChange={handleRadioChange}
              value={SyncType.FULL}
              name="sync-type"
              inputProps={{ "aria-label": "Full Sync" }}
            />
          }
          label={t("fortnox:radio_full")}
        />

        {syncType === SyncType.FULL && (
          <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel>{t("fortnox:from_start_of")}</InputLabel>
            <Select
              value={year}
              onChange={handleYearChange}
              variant="outlined"
              sx={{ color: "black", mt: 2 }}
              MenuProps={{
                PaperProps: {
                  variant: "outlined",
                  sx: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </FormControl>
    </Box>
  );
};

export default SyncConnectionsDataForm;
