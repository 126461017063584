import { FC, useState } from "react";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { ButtonProps } from "@mui/material";

export interface Props {
  buttonText?: string;
  buttonIcon?: ReactNode;
  buttonColor?: string;
  dialogTitle?: string;
  dialogContent?: ReactNode;
  disabled?: string;
  dialogActionText?: string;
  dialogActionColor?: string;
  onAction?: (closeDialog?: () => void) => Promise<void>;
}

const ActionButton: FC<Props> = ({
  dialogTitle,
  dialogContent,
  onAction,
  disabled,
  buttonText,
  buttonIcon,
  buttonColor = "primary",
  dialogActionText,
  dialogActionColor = "primary",
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = async () => {
    setIsLoading(true);
    if (onAction) {
      await onAction(() => setOpen(false));
    }
    setIsLoading(false);
  };

  const isDialog = dialogTitle && dialogContent;
  const disabledBool = Boolean(disabled);

  return (
    <>
      <Tooltip
        disableHoverListener={!disabledBool}
        disableTouchListener={!disabledBool}
        disableFocusListener={!disabledBool}
        title={disabled}
      >
        <Box component={"span"}>
          <Button
            size="small"
            variant="outlined"
            color={buttonColor as ButtonProps["color"]}
            disabled={disabledBool}
            startIcon={buttonIcon}
            onClick={() => setOpen(true)}
          >
            {buttonText}
          </Button>
        </Box>
      </Tooltip>
      {isDialog && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          title={dialogTitle}
          actions={
            <>
              <Button disabled={isLoading} onClick={() => setOpen(false)}>
                {t("cancel")}
              </Button>
              {onAction && (
                <Button
                  disabled={isLoading}
                  onClick={handleAction}
                  variant="contained"
                  color={dialogActionColor as ButtonProps["color"]}
                >
                  {isLoading ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : dialogActionText ? (
                    dialogActionText
                  ) : (
                    t("confirm")
                  )}
                </Button>
              )}
            </>
          }
        >
          {dialogContent}
        </Dialog>
      )}
    </>
  );
};

export default ActionButton;
