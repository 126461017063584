import {Breadcrumbs, Link, Typography} from "@mui/material";
import {BreadcrumbItem} from "types";
import {FC} from "react";

interface Props {
  breadcrumbItems: BreadcrumbItem[];
  handleClick: (index: number) => void;
  isRootPath: boolean;
  rootPath: string;
}

const BreadcrumbTrail: FC<Props> = ({breadcrumbItems, handleClick, isRootPath, rootPath}) => {

  return (
    <Breadcrumbs color="white">
      {breadcrumbItems.length > 0 ? (
        <Link
          underline="hover"
          color="white"
          onClick={() => handleClick(0)}
          style={{cursor: "pointer"}}
        >
          {rootPath}
        </Link>
      ) : (
        <Typography color="white">
          {rootPath}
        </Typography>
      )}


      {!isRootPath &&
        breadcrumbItems.map((item, index) => {
          const isLast = index === breadcrumbItems.length - 1;
          const displayText = item.name || item.type;

          if (!displayText) return null;

          return isLast ? (
            <Typography color="white" key={item.id}>
              {displayText}
            </Typography>
          ) : (
            <Link
              underline="hover"
              color="white"
              onClick={() => handleClick(index + 1)}
              key={item.id}
              style={{cursor: "pointer"}}
            >
              {displayText}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;