import { FC } from "react";
import { DeleteRounded as DeleteIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ActionButton, ActionButtonProps } from ".";

export interface Props
  extends Omit<ActionButtonProps, "actionType" | "onAction"> {
  onDelete?: ActionButtonProps["onAction"];
}

const DeleteButton: FC<Props> = ({
  onDelete,
  buttonText,
  buttonIcon,
  dialogActionText,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <ActionButton
      onAction={onDelete}
      buttonText={buttonText || t("delete")}
      buttonIcon={buttonIcon || <DeleteIcon />}
      dialogActionText={dialogActionText || t("delete")}
      {...props}
    />
  );
};

export default DeleteButton;
