import { ReactNode } from "react";
import {
  IconButton,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import { CloseRounded as CloseIcon } from "@mui/icons-material";

export interface Props {
  open: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
  actions?: ReactNode;
}

const Dialog = ({ open, title, children, onClose, actions }: Props) => {
  return (
    <MuiDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Stack
        component={DialogTitle}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="p">
          {title}
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
