import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid2 as Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { withAuthentication } from "auth";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  getActivationUrl,
  getTokenExpiryUTC,
  getVersions,
  triggerTokenRefresh,
} from "api/callbacks";
import { Versions } from "types";

const UI_VERSION = process.env.REACT_APP_VERSION;

const Settings = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [valid, setValid] = useState<string | undefined>();
  const [versions, setVersions] = useState<Versions>();
  const [loading, setLoading] = useState(true);

  const getStatusText = () => {
    if (loading) {
      return t("loading");
    } else {
      return valid && valid.length > 0
        ? t("setting:fabric_connection_valid_until", { until: valid })
        : t("setting:fabric_connection_expired");
    }
  };

  const loadTokenExpiry = useCallback(async () => {
    try {
      setLoading(true);
      const { data: expiry } = await getTokenExpiryUTC();
      setValid(expiry);
    } catch (error) {
      // we assume token is expired, and show expired status
      console.error("Error getting token expiry:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const triggerRefresh = useCallback(async () => {
    try {
      setLoading(true);
      const { data: valid } = await triggerTokenRefresh();
      setValid(valid);
    } catch (error) {
      enqueueSnackbar(t("setting:fabric_connection_failed"), {
        variant: "error",
      });
      console.error("Error getting token expiry:", error);
    } finally {
      setLoading(false);
    }
  }, [t, enqueueSnackbar]);

  const loadVersions = useCallback(async () => {
    try {
      const { data: versions } = await getVersions();
      setVersions(versions);
    } catch (error) {
      enqueueSnackbar(t("setting:version_failed"), { variant: "warning" });
      console.error("Error getting app versions:", error);
    }
  }, [t, enqueueSnackbar]);

  const gotoActivationUrl = useCallback(async () => {
    const { data: url } = await getActivationUrl();
    window.open(url.activationUrl);
  }, []);

  useEffect(() => {
    loadTokenExpiry();
    loadVersions();
  }, [loadTokenExpiry, loadVersions]);

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t("setting:fabric_connection")}
              </Typography>
              <Typography variant="body2">
                {t("setting:fabric_connection_status")}: {getStatusText()}
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" size="small" onClick={triggerRefresh}>
                {t("setting:refresh_fabric_token")}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={gotoActivationUrl}
              >
                {t("setting:create_new_fabric_token")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid size={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t("setting:version_title")}
              </Typography>
              <Typography variant="body2">
                <List>
                  <ListItem>
                    {t("setting:version_UI_label")}:{" "}
                    {t("setting:version_UI_value", { version: UI_VERSION })}
                    {}
                  </ListItem>
                  <ListItem>
                    {t("setting:version_API_label")}:{" "}
                    {t("setting:version_API_value", {
                      version: versions?.api,
                    })}
                  </ListItem>
                  <ListItem>
                    {t("setting:version_NoteBook_label")}:{" "}
                    {t("setting:version_NoteBook_value", {
                      sha: versions?.notebook.sha,
                      date: versions?.notebook.date,
                    })}
                  </ListItem>
                </List>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withAuthentication(Settings);
