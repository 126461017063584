import { axiosInstance } from "utils";
import { FortnoxActivationResponse } from "types";

export const getTokenExpiryUTC = async () => {
  return await axiosInstance.get<string>("fabric/activation/token/expiresAt");
};

export const getActivationUrl = async () => {
    return await axiosInstance.get<FortnoxActivationResponse>("fabric/activation/url");
};

export const triggerTokenRefresh = async () => {
    return await axiosInstance.post<string>("fabric/activation/token/refresh");
};
