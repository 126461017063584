import { useCallback } from "react";
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
  CircularProgress,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { t } from "i18next";

import { SortOrder, TableProps } from "types";

const getTableCellWidth = (width: number | undefined): string | undefined =>
  width !== undefined ? `${width * 10}%` : undefined;

export const Table = <T,>({
  columns,
  data,
  sort,
  onSort,
  selectable,
  selected,
  onSelect,
  isLoading,
}: TableProps<T>) => {
  const onSortColumn = useCallback(
    (columnName: string): void => {
      if (onSort && sort !== undefined)
        onSort(
          (() => {
            if (sort === null || sort.columnName !== columnName)
              return { columnName, direction: SortOrder.ASC };
            else if (sort?.direction === SortOrder.ASC)
              return { columnName, direction: SortOrder.DESC };
            return null;
          })()
        );
    },
    [onSort, sort]
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ position: "relative", overflow: "auto" }}
    >
      <MuiTable stickyHeader>
        <TableHead>
          <TableRow>
            {[
              ...(selectable
                ? [{ name: "selection", label: "", width: 0.1 }]
                : []),
              ...columns,
            ].map(({ name, label, width, sortDisabled, ...column }, key) => (
              <TableCell
                key={key}
                width={getTableCellWidth(width)}
                {...column}
                sortDirection={sort?.direction}
              >
                {sort !== undefined ? (
                  <TableSortLabel
                    onClick={() => onSortColumn(name)}
                    active={sort?.columnName === name}
                    direction={sort?.direction}
                    disabled={sortDisabled}
                  >
                    {label}
                  </TableSortLabel>
                ) : (
                  label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data.length || isLoading ? (
            <TableRow>
              <TableCell
                colSpan={columns.length + (selectable ? 1 : 0)}
                align="center"
              >
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  t("alert:data_empty")
                )}
              </TableCell>
            </TableRow>
          ) : (
            data.map(({ item, values, ...row }, i) => {
              const isSelected = selected?.some(
                (selectedItem) => selectedItem === item
              );

              return (
                <TableRow key={i} {...row}>
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={!!isSelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => onSelect && onSelect(item)}
                      />
                    </TableCell>
                  )}
                  {values.map(({ value, ...props }, index) => (
                    <TableCell
                      key={index}
                      width={getTableCellWidth(columns.at(index)?.width)}
                      {...props}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
