import React from "react";
import { Box } from "@mui/material";

import Navigation from "components/Navigation";
import AppBar from "components/AppBar";

interface Props {
  children: React.ReactNode;
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Navigation />
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <AppBar />
        {children}
      </Box>
    </Box>
  );
};
