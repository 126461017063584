import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { sendActivationLinkEmail } from "api/callbacks/fortnox";
import { ContentCopy } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import {ActivationLinkMailPayload, FortnoxActivationResponse} from "types";
import {AxiosResponse} from "axios";

type Props = {
  createResponse?: AxiosResponse<FortnoxActivationResponse>;
  onClose: () => void;
};

const EmailDialog: FC<Props> = ({ createResponse, onClose }) => {
  const { t } = useTranslation();
  const [activationUrl, setActivationUrl] = useState<string | undefined>();
  const [copied, setCopied] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);

  const isValidEmail = (email: string): boolean => {
    if (!email) return false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendEmail = async () => {
    if (!email || !activationUrl || !createResponse?.data?.activationLinkId)
      return;
    setSending(true);
    try {
      const payload: ActivationLinkMailPayload = { email: email };
      const result = await sendActivationLinkEmail(
        createResponse?.data?.activationLinkId,
        payload,
      );
      result
        ? enqueueSnackbar(
            t("alert:send_activation_link_mail_success", {
              email: email,
            }),
            { variant: "success" },
          )
        : enqueueSnackbar(t("alert:send_mail_error"), { variant: "error" });
    } catch (error) {
      enqueueSnackbar(t("alert:send_mail_error"), { variant: "error" });
      console.error("Error sending email:", error);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (createResponse?.data?.activationUrl)
      setActivationUrl(createResponse?.data?.activationUrl);
  }, [createResponse]);

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle fontSize={"large"}>
        {t("fortnox:activation_url_dialog_title")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ gap: 4 }}
          >
            <Typography>{activationUrl}</Typography>
            <Button
              onClick={() => {
                if (copied) return;
                navigator.clipboard.writeText(activationUrl || "");
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              }}
              color={copied ? "success" : "primary"}
              variant="contained"
            >
              {!copied && <ContentCopy />}
              {copied ? t("copied") : t("copy")}
            </Button>
          </Box>
          <Divider></Divider>
          <Box>
            <Typography sx={{ mb: 0.5, mt: 2 }}>Send to</Typography>

            <TextField
              label={t("email")}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ mt: 4 }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="text"
          disabled={sending}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSendEmail}
          color="primary"
          variant="contained"
          disabled={!isValidEmail(email) || sending}
        >
          {sending ? t("sending") : t("send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDialog;
