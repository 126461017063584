import { FC, ReactNode } from "react";
import { ThemeProvider as Provider, CssBaseline } from "@mui/material";

import { theme } from "utils";

interface Props {
  children: ReactNode;
}

const ThemeProvider: FC<Props> = ({ children }) => (
  <Provider theme={theme}>
    <CssBaseline enableColorScheme />
    {children}
  </Provider>
);

export default ThemeProvider;
