import axios, { AxiosHeaders } from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "index";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.error("No active account! Unable to acquire access token.");
      return config;
    }

    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_API_SCOPE!],
      account: account,
    };

    try {
      const response = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      const token = response.accessToken;

      if (token) {
        if (!config.headers) {
          config.headers = new AxiosHeaders();
        }
        (config.headers as AxiosHeaders).set(
          "Authorization",
          `Bearer ${token}`
        );
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.error(
          "Interaction required to acquire token. Redirecting to login."
        );
      } else {
        console.error("Token acquisition failed:", error);
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API call error:", error);
    return Promise.reject(error);
  }
);
