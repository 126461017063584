import { FC } from "react";

import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  Login,
  Organisations,
  OrganisationClients,
  OrganisationClientConnections,
  Settings,
} from "pages";

export interface Props {
  path: string;
  Component: FC;
  onNav: boolean;
  bottomStack?: boolean;
  titleKey?: string;
  Icon?: FC;
}

const ROUTES: Props[] = [
  {
    path: "/",
    Component: Login,
    onNav: false,
  },
  {
    path: "/organisations",
    Component: Organisations,
    onNav: true,
    titleKey: "organisations",
    Icon: ApartmentIcon,
  },
  {
    path: "/organisations/:id",
    Component: OrganisationClients,
    onNav: false,
  },
  {
    path: "/organisations/:id/clients/:clientId",
    Component: OrganisationClientConnections,
    onNav: false,
  },
  {
    path: "/settings",
    Component: Settings,
    onNav: true,
    bottomStack: true,
    titleKey: "settings",
    Icon: SettingsIcon,
  },
];

export default ROUTES;
