import React from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

export const withAuthentication = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  return (props: P) => (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <WrappedComponent {...props} />
    </MsalAuthenticationTemplate>
  );
};
