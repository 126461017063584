import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import ROUTES from "routes";
import { MainLayout } from "layouts";
import { Login } from "pages";

const App = () => {
  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <MainLayout>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/organisations" replace />}
            />
            {ROUTES.filter((route) => route.path !== "/").map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <Route
              path="*"
              element={<Navigate to="/organisations" replace />}
            />
          </Routes>
        </MainLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
};

export default App;
