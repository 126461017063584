import { FC } from "react";
import { AddRounded as AddIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ActionButton, ActionButtonProps } from ".";

export interface Props
  extends Omit<ActionButtonProps, "actionType" | "onAction"> {
  onCreate?: ActionButtonProps["onAction"];
}

const CreateButton: FC<Props> = ({
  onCreate,
  buttonText,
  buttonIcon,
  dialogActionText,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <ActionButton
      onAction={onCreate}
      buttonText={buttonText || t("create")}
      buttonIcon={buttonIcon || <AddIcon />}
      dialogActionText={dialogActionText || t("create")}
      {...props}
    />
  );
};

export default CreateButton;
