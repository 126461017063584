import { FC } from "react";
import { Button, Box, Grid2 as Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

import LanguageSelector from "components/LanguageSelector";
import sevrBlackLogo from "assets/images/sevr-black.png";
import { ComponentColorMode } from "types";
import { loginRequest } from "authConfig";

const Login: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();

  const handleLogin = () => {
    if (inProgress === InteractionStatus.None) {
      instance
        .loginPopup(loginRequest)
        .then(() => {
          navigate("/organisations");
        })
        .catch((error) => {
          console.error("Login failed", error);
          if (error.errorCode === "popup_window_error") {
            alert(t("alert:popup_blocked"));
          }
        });
    } else {
      console.warn("Login interaction is already in progress.");
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box hidden={true} sx={{ position: "absolute", top: 16, right: 16 }}>
          <LanguageSelector mode={ComponentColorMode.LIGHT} />
        </Box>

        <Box
          textAlign="center"
          style={{ width: "100%", maxWidth: "400px", padding: "20px" }}
        >
          <Typography variant="h4">{t("auth:login_title")}</Typography>
          <Typography variant="body2" sx={{ marginBottom: 3 }}>
            {t("auth:login_subheader")}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
          >
            {t("auth:login_with_microsoft")}
          </Button>
        </Box>
      </Grid>

      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0A0E16",
          color: "#fff",
        }}
      >
        <Box textAlign={"center"}>
          <img
            src={sevrBlackLogo}
            alt="Sevr Logo"
            style={{ width: "60%", height: "auto" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
