import { Theme, createTheme } from "@mui/material";

const primary = "#CF903A";
const secondary = "#FFF";
const error = "#ff3363";
const warning = "#ec5b56";
const info = "#0288d1";
const success = "#388e3c";

const textPrimary = "#212121";
const textSecondary = "#757575";
const textDisabled = "#BDBDBD";

const divider = "#E0E0E0";

const bgDefault = "#fafafa";
const bgPaper = "#ffffff";

const spacing = 4;

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: primary,
      // light: "#63a4ff",
      // dark: "#004ba0",
       contrastText: "#ffffff",
    },
    secondary: {
      main: secondary,
      // light: "#d05ce3",
      // dark: "#6a0080",
      contrastText: "#000000",
    },
    error: {
      main: error,
      // light: "#ff6659",
      // dark: "#9a0007",
      contrastText: "#ffffff",
    },
    warning: {
      main: warning,
      // light: "#ffad42",
      // dark: "#bb4d00",
      // contrastText: "#000000",
    },
    info: {
      main: info,
      // light: "#5eb8ff",
      // dark: "#005b9f",
      contrastText: "#ffffff",
    },
    success: {
      main: success,
      // light: "#6abf69",
      // dark: "#00600f",
      contrastText: "#ffffff",
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      disabled: textDisabled,
    },
    background: {
      default: bgDefault,
      paper: bgPaper,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider,
    mode: "light",
  },
  spacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', "sans-serif"].join(", "),
    h1: {
      fontSize: "2.125rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    h6: {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: "#0a0e16",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          borderColor: "#ffffff",
        },
        icon: {
          color: "#ffffff",
        },
      },
    },
  },
});
