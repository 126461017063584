import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { withAuthentication } from "auth";
import { Organisation, OrganisationFormValues } from "types";
import {
  fetchOrganisations,
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
} from "api/callbacks";
import { TableColumn, TableDataRow } from "types/table";
import { OrganisationForm } from "components/Forms/OrganisationForm";
import { handleTableAction } from "utils/helpers/handleTableAction";
import { ActionTable } from "components/Table";
import ConfirmationForm from "components/Forms/ConfirmationForm";

const Organisations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<OrganisationFormValues>();
  const [selected, setSelected] = useState<Organisation[]>([]);

  const loadOrganisations = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: fetchedOrganisations } = await fetchOrganisations();
      setOrganisations(fetchedOrganisations);
    } catch (error) {
      console.error("Failed to fetch organisations", error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOrganisations();
  }, [loadOrganisations]);

  const columns = useMemo(
    (): TableColumn[] => [
      { name: "organisation", label: t("organisation:organisation"), width: 9 },
      { name: "clients", label: t("clients"), width: 1 },
    ],
    [t]
  );

  const data = useMemo(
    (): TableDataRow<Organisation>[] =>
      organisations.map((organisation) => ({
        item: organisation,
        hover: true,
        sx: { cursor: "pointer" },
        onClick: () => navigate(`/organisations/${organisation.id}`),
        values: [
          {
            name: "organisation",
            value: organisation.name,
          },
          {
            name: "client",
            value: organisation.clientNr,
          },
        ],
      })),
    [organisations, navigate]
  );

  const handleCreate = useCallback(
    async (closeDialog?: () => void) => {
      if (formData) {
        await handleTableAction({
          apiCall: () => createOrganisation(formData),
          successMessage: t("alert:create_success", {
            type: t("organisation:organisation"),
            name: formData.name,
          }),
          failureMessage: t("alert:create_fail", {
            type: t("organisation:organisation"),
            name: formData.name,
          }),
          closeDialog,
          callback: loadOrganisations,
        });
      }
    },
    [formData, t, loadOrganisations]
  );

  const handleEdit = useCallback(
    async (closeDialog?: () => void) => {
      if (selected.length !== 1 || !formData) return;
      const organisationId = selected[0].id;
      await handleTableAction({
        apiCall: () => updateOrganisation(organisationId, formData),
        successMessage: t("alert:update_success", {
          type: t("organisation:organisation"),
          name: formData.name,
        }),
        failureMessage: t("alert:update_fail", {
          type: t("organisation:organisation"),
          name: formData.name,
        }),
        closeDialog,
        callback: loadOrganisations,
      });
      setSelected([]);
    },
    [selected, formData, t, loadOrganisations]
  );

  const handleDelete = useCallback(
    async (closeDialog?: () => void) => {
      if (selected.length !== 1) return;
      const organisation = selected[0];
      await handleTableAction({
        apiCall: () => deleteOrganisation(organisation.id),
        successMessage: t("alert:delete_success", {
          type: t("organisation:organisation"),
          name: organisation.name,
        }),
        failureMessage: t("alert:delete_fail", {
          type: t("organisation:organisation"),
          name: organisation.name,
        }),
        closeDialog,
        callback: loadOrganisations,
      });
      setSelected([]);
    },
    [selected, t, loadOrganisations]
  );

  const onSelect = useCallback((item: Organisation) => {
    const itemId = item.id;
    setSelected((prevState) =>
      prevState.some((selectedItem) => selectedItem.id === itemId)
        ? prevState.filter((selectedItem) => selectedItem.id !== itemId)
        : [...prevState, item]
    );
  }, []);

  const disabledStates = useMemo(
    () => ({
      edit:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
          ? t("alert:multiple_items_selected")
          : undefined,

      delete:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
          ? t("alert:multiple_items_selected")
          : selected[0].clientNr > 0
          ? t("alert:organisation_delete_disabled_reason")
          : undefined,
    }),
    [selected, t]
  );

  const actions = useMemo(
    () => ({
      create: {
        dialogTitle: t("organisation:add_new_organisation"),
        dialogContent: (
          <OrganisationForm onChange={setFormData} isLoading={isLoading} />
        ),
        onCreate: handleCreate,
      },
    }),
    [t, isLoading, handleCreate]
  );

  const selectedActions = useMemo(() => {
    return {
      edit: {
        dialogTitle: t("organisation:edit_organisation"),
        dialogContent: (
          <OrganisationForm
            initialData={organisations.find(
              (org) => org.id === selected[0]?.id
            )}
            onChange={setFormData}
            isLoading={isLoading}
          />
        ),
        disabled: disabledStates.edit,
        onEdit: handleEdit,
      },
      delete: {
        dialogTitle: t("organisation:delete_organisation"),
        dialogContent: (
          <ConfirmationForm
            itemName={selected[0]?.id}
            itemType={t("connection:connection")}
            additionalWarning={t("cant_undo")}
          />
        ),
        disabled: disabledStates.delete,
        onDelete: handleDelete,
      },
    };
  }, [
    t,
    organisations,
    isLoading,
    disabledStates,
    selected,
    handleEdit,
    handleDelete,
  ]);

  const onRefetch = useCallback(() => {
    setSelected([]);
    loadOrganisations();
  }, [loadOrganisations]);

  return (
    <ActionTable<Organisation>
      {...{
        columns,
        data,
        isLoading,
        selected,
        onSelect,
        actions,
        selectedActions,
        onRefetch,
      }}
    />
  );
};

export default withAuthentication(Organisations);
