import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConfirmationFormProps {
  itemName: string;
  itemType: string;
  additionalWarning?: string;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  itemName,
  itemType,
  additionalWarning,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>
        {t("alert:delete_confirm", { itemName, type: itemType })}
      </Typography>

      {additionalWarning && (
        <Typography fontWeight="bold" color="error" mt={1}>
          {additionalWarning}
        </Typography>
      )}
    </Box>
  );
};

export default ConfirmationForm;
