import {BreadcrumbItem} from "../../types";
import {axiosInstance} from "../../utils";

export const fetchBreadcrumbData = async (
  breadcrumbItems: BreadcrumbItem[]
): Promise<BreadcrumbItem[]> => {
  const response = await axiosInstance.post<BreadcrumbItem[]>(
    "/navigation/breadcrumbs",
    breadcrumbItems
  );
  return response.data;
};