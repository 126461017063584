import { Stack } from "@mui/material";

import {
  ActionToolbar,
  ToolbarActions,
  Table,
  SelectableToolbarActions,
} from "components/Table";
import { TableProps } from "types";

interface Props<T> extends TableProps<T> {
  actions?: ToolbarActions;
  selectedActions?: SelectableToolbarActions;
  onRefetch?: () => void;
}

export const ActionTable = <T,>({
  actions,
  selectedActions,
  onRefetch,
  ...props
}: Props<T>) => {
  return (
    <Stack flex={1} overflow="hidden" p={2}>
      <ActionToolbar
        {...{ selected: props.selected, actions, selectedActions, onRefetch }}
      />
      <Table selectable {...props} />
    </Stack>
  );
};
