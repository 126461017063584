import {FC, useCallback, useEffect, useMemo, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar as MuiAppBar, Box, IconButton, Toolbar } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import * as signalR from "@microsoft/signalr";
import { useTranslation } from "react-i18next";

import Notifications from "components/Notifications";
import BreadcrumbTrail from "components/BreadcrumbTrail";
import { fetchBreadcrumbData, fetchJobStatuses } from "api/callbacks";
import {BreadcrumbItem, BreadcrumbType, JobStatus} from "types";
import { getAccessToken } from "auth";

const BACKBUTTON_BLACKLIST = ["/organisations", "/settings"];

const AppBar: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { instance, inProgress } = useMsal();
  const [jobStatuses, setJobStatuses] = useState<JobStatus[]>([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  const showBackButton = !BACKBUTTON_BLACKLIST.includes(pathname);
  const pathNames = useMemo(() => 
    pathname.split("/").filter((x) => x),
    [pathname]);

  const mapPathToBreadcrumb = useCallback(() => {
    const itemsFromPath: BreadcrumbItem[] = [];
    const data = pathNames;

    for (let i = 0; i < data.length; i += 2) {
      const key = data[i];
      const value = data[i + 1];

      if (key && value) {
        itemsFromPath.push({ type: key as BreadcrumbType, id: value });
      }
    }
    return itemsFromPath;
  }, [pathNames]);

  useEffect(() => {
    const loadBreadcrumbs = async () => {
      if (pathNames.length === 1) {
        setBreadcrumbItems([]);
        return;
      }

      const itemsToFetch = mapPathToBreadcrumb();

      if (itemsToFetch.length > 0) {
        try {
          const names = await fetchBreadcrumbData(itemsToFetch);
          setBreadcrumbItems(names);
        } catch (error) {
          console.error("Error fetching breadcrumb names:", error);
        }
      }
    };

    loadBreadcrumbs();
  }, [pathNames, mapPathToBreadcrumb]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: jobs } = await fetchJobStatuses();
      setJobStatuses(jobs);
    };
    fetchData();

    const initialise = async () => {
      const token = await getAccessToken(instance, inProgress, [
        process.env.REACT_APP_API_SCOPE!,
      ]);
      if (token) {
        const hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_SIGNALR_HOST}/jobstatus`, {
            accessTokenFactory: () => token,
          })
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();

        if (!hubConnection) {
          throw new Error("Failed to create SignalR connection");
        }

        hubConnection
          .start()
          .then(() => {
            hubConnection.on("JobStatusUpdate", (message) => {
              const jobStatus = message;
              setJobStatuses((current) => {
                const index = current.findIndex((js) => js.id === jobStatus.id);
                if (index > -1) {
                  const updatedStatuses = [...current];
                  updatedStatuses[index] = jobStatus;
                  return updatedStatuses;
                } else {
                  return [jobStatus, ...current];
                }
              });
            });
          })
          .catch((err) => console.error("SignalR Connection failed: ", err));
        return () => {
          hubConnection && hubConnection.stop();
        };
      }
    };
    initialise();
  }, [inProgress, instance]);

  const handleClick = (index: number) => {
    if (index === 0) {
      navigate(`/${pathNames[0]}`);
      setBreadcrumbItems([]);
    } else {
      const pathToNavigate = breadcrumbItems
        .slice(0, index)
        .map((item) => `/${item.type.toLowerCase()}/${item.id}`)
        .join("");
      navigate(pathToNavigate);
    }
  };

  return (
    <MuiAppBar position="static">
      <Toolbar>
        {showBackButton && (
          <Box sx={{ ml: 2 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              <ArrowBack />
            </IconButton>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <BreadcrumbTrail
            breadcrumbItems={breadcrumbItems}
            handleClick={handleClick}
            isRootPath={breadcrumbItems.length === 0}
            rootPath={t(`${pathNames[0]}`)}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Notifications notifications={jobStatuses} />
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
