import React, { FC, useState } from "react";
import {
  Badge,
  IconButton,
  Menu,
  List,
  ListItem,
  ListItemText,
  Box,
  LinearProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Check, Close, Loop } from "@mui/icons-material";

import { JobStatus } from "types";

interface Props {
  notifications: JobStatus[];
}

const Notifications: FC<Props> = ({ notifications }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDone = (jobStatus: JobStatus): boolean => {
    return jobStatus.totalSteps === jobStatus.completedSteps;
  };

  const getPercentage = (jobStatus: JobStatus): number => {
    if (jobStatus.completedSteps === jobStatus.totalSteps) {
      return 100;
    }

    return (
      (jobStatus.completedSteps / jobStatus.totalSteps) * 100 +
      jobStatus.stepPercentage * (1 / jobStatus.totalSteps)
    );
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <List sx={{ minWidth: "5em" }}>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <ListItem key={index} sx={{ minWidth: "20em" }}>
                {isDone(notification) ? (
                  <Check />
                ) : notification.hasError === true ? (
                  <Close />
                ) : (
                  <Loop />
                )}
                <Box sx={{ width: "100%", padding: "0em 1em" }}>
                  <ListItemText
                    primary={`${notification.clientName}: ${notification.connectionType}: ${notification.syncType}`}
                    secondary={notification.status}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={getPercentage(notification)}
                    color={notification.hasError ? "error" : "success"}
                  />
                </Box>
              </ListItem>
            ))
          ) : (
            <ListItem sx={{ minWidth: "20em" }}>
              <ListItemText primary="No running jobs" />
            </ListItem>
          )}
        </List>
      </Menu>
    </>
  );
};

export default Notifications;
