import { FC } from "react";
import { EditRounded as EditIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ActionButton, ActionButtonProps } from ".";

export interface Props
  extends Omit<ActionButtonProps, "actionType" | "onAction"> {
  onEdit?: ActionButtonProps["onAction"];
}

const EditButton: FC<Props> = ({
  onEdit,
  buttonText,
  buttonIcon,
  dialogActionText,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <ActionButton
      onAction={onEdit}
      buttonText={buttonText || t("edit")}
      buttonIcon={buttonIcon || <EditIcon />}
      dialogActionText={dialogActionText || t("save_changes")}
      {...props}
    />
  );
};

export default EditButton;
