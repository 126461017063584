import {
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";

export const getAccessToken = async (
  instance: IPublicClientApplication,
  inProgress: InteractionStatus,
  scopes: string[]
): Promise<string | undefined> => {
  const account = instance.getActiveAccount();
  if (!account) {
    throw new Error("No active account found");
  }

  try {
    const response = await instance.acquireTokenSilent({
      scopes,
      account,
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      if (inProgress === InteractionStatus.None) {
        const response = await instance.acquireTokenPopup({
          scopes,
          account,
        });
        return response.accessToken;
      } else {
        console.error(error);
      }
    } else {
      console.error("Error acquiring token:", error);
    }
  }
};
