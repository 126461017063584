import { FC, useEffect } from "react";
import { Box, Stack, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrganisationFormValues } from "types";
import { Controller, useForm } from "react-hook-form";

interface Props {
  onChange: (values: OrganisationFormValues) => void;
  initialData?: OrganisationFormValues;
  isLoading: boolean;
}

export const OrganisationForm: FC<Props> = ({
  onChange,
  initialData,
  isLoading,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<OrganisationFormValues>({
    defaultValues: {
      name: initialData?.name ?? "",
    },
  });

  const formValues = watch();

  useEffect(() => {
    onChange(formValues);
  }, [formValues, onChange]);

  return (
    <Box component="form" sx={{ mt: 2 }} onSubmit={(e) => e.preventDefault()}>
      <Stack spacing={2}>
        <Controller
          name="name"
          control={control}
          rules={{ required: t("alert:organisation_name_required") }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("organisation:organisation_name")}
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
              disabled={isLoading}
            />
          )}
        />
        {isLoading && (
          <Box mt={2} display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        )}
      </Stack>
    </Box>
  );
};
