import { axiosInstance } from "utils";
import { Organisation, OrganisationFormValues } from "types";

export const fetchOrganisations = async () => {
  return await axiosInstance.get<Organisation[]>("/organisations");
};

export const createOrganisation = async (
  organisation: OrganisationFormValues
) => {
  return await axiosInstance.post<Organisation>("/organisations", organisation);
};

export const updateOrganisation = async (
  id: string,
  organisation: OrganisationFormValues
) => {
  return await axiosInstance.put<Organisation>(
    `/organisations/${id}`,
    organisation
  );
};

export const deleteOrganisation = async (id: string) => {
  return await axiosInstance.delete(`/organisations/${id}`);
};
