import { FC, useState } from "react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import { useTranslation } from "react-i18next";
import type { SelectChangeEvent } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { Connection, ConnectionType } from "types";
import FortnoxConnection from "components/Forms/FortnoxConnectionForm";

interface Props {
  onChange?: (value: string[]) => void;
  clientId: string;
  initialData?: Connection;
}

export const ConnectionForm: FC<Props> = (props) => {
  const {initialData} = props;
  const { t } = useTranslation();
  const [connectionType, setConnectionType] = useState<ConnectionType>(
    initialData?.type ?? ConnectionType.Fortnox
  );

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);

  const handleConnectionTypeChange = (event: SelectChangeEvent<unknown>) => {
    const newConnectionType = event.target.value as ConnectionType;
    setConnectionType(newConnectionType);
  };

  return (
    <Box>
      <FormControl fullWidth sx={{mt: 2}}>
        <InputLabel sx={{ml: -3.5}}>{initialData ? t("connection:connection_type") : t("connection:source")}</InputLabel>
        <Select
          IconComponent={() =>
            dropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
          }
          disabled={Boolean(initialData)}
          sx={{ color: "black", width: "60%" }}
          value={connectionType}
          onChange={handleConnectionTypeChange}
          variant={"standard"}
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        >
          <MenuItem value={ConnectionType.Fortnox}>
            {ConnectionType[ConnectionType.Fortnox]}
          </MenuItem>
        </Select>
      </FormControl>
      

      <Box mt={4}>
        {connectionType === ConnectionType.Fortnox && (
          <FortnoxConnection
            {...props}
            {...{
              loading,
              setLoading,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

