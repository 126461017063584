import { AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";

interface handleTableActionsProps {
  apiCall: () => Promise<AxiosResponse>;
  successMessage?: string;
  failureMessage?: string;
  callback?: (result: any) => void;
  closeDialog?: () => void;
}

export const handleTableAction = async ({
  apiCall,
  successMessage,
  failureMessage,
  closeDialog,
  callback,
}: handleTableActionsProps) : Promise<boolean> => {
  try {
    const result = await apiCall();
    successMessage && enqueueSnackbar(successMessage, { variant: "success" });
    closeDialog && closeDialog();
    callback && callback(result);
    return true;
  } catch (error) {
    console.error(failureMessage, error);
    failureMessage && enqueueSnackbar(failureMessage, { variant: "error" });
    return false;
  }
};
