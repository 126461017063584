import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { DownloadForOffline, Visibility as Preview } from "@mui/icons-material";

import {
  Connection,
  ConnectionType,
  FortnoxFetchPayload,
  FortnoxCompanyInformation,
  SyncType, FortnoxActivationResponse,
} from "types";
import {
  deleteClientConnection,
  fetchClientConnections,
  fetchFortnoxActivationUrl,
  fortnoxFetch,
} from "api/callbacks";
import { TableColumn, TableDataRow } from "types/table";
import { ConnectionForm } from "components/Forms/ConnectionForm";
import { ActionTable } from "components/Table";
import { withAuthentication } from "auth";
import ConfirmationForm from "components/Forms/ConfirmationForm";
import SyncConnectionsDataForm from "components/Forms/SyncConnectionsForm";
import { handleTableAction } from "utils";
import { Box } from "@mui/material";
import EmailDialog from "components/EmailDialog";
import {AxiosResponse} from "axios";


const OrganisationClientConnections = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { clientId } = useParams<{ clientId: string }>();
  const [syncType, setSyncType] = useState<SyncType | undefined>();
  const [year, setYear] = useState<number>(2020);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<Connection[]>([]);
  const [formData, setFormData] = useState<string[]>([]);
  const [createResponse, setCreateResponse] = useState<AxiosResponse<FortnoxActivationResponse>>();

  const loadConnections = useCallback(async () => {
    setIsLoading(true);
    if (!clientId) return;
    try {
      const { data: fetchedConnections } =
        await fetchClientConnections(clientId);
      setConnections(fetchedConnections);
    } catch (error) {
      console.error("Error fetching connections:", error);
      enqueueSnackbar(t("alert:error_loading_connections"), {
        variant: "error",
      });
    }
    setIsLoading(false);
  }, [clientId, enqueueSnackbar, t]);

  useEffect(() => {
    loadConnections();
  }, [loadConnections]);

  const handleChange = (syncType: SyncType, syncFromYear: number) => {
    setSyncType(syncType);
    setYear(syncFromYear);
  };

  const extractCompanyInformation = (metadata?: { [key: string]: string }) => {
    const companyInfo: FortnoxCompanyInformation | undefined =
      metadata?.companyInformation
        ? JSON.parse(metadata.companyInformation)
        : undefined;

    return {
      companyName: companyInfo?.CompanyName,
      organisationNumber: companyInfo?.OrganizationNumber,
    };
  };

  const columns = useMemo(
    (): TableColumn[] => [
      { name: "connection", label: t("connection:connection"), width: 2 },
      {
        name: "organisation_number",
        label: t("organisation:organisation_number"),
        width: 2,
      },
      { name: "source", label: t("connection:source"), width: 2 },
      { name: "last_sync", label: t("connection:last_sync"), width: 1 },
    ],
    [t],
  );

  const data = useMemo(
    (): TableDataRow<Connection>[] =>
      connections.map((connection) => {
        const { companyName, organisationNumber } = extractCompanyInformation(
          connection.metadata,
        );
        return {
          item: connection,
          values: [
            { name: "connection", value: companyName },
            { name: "organisation_number", value: organisationNumber },
            { name: "source", value: ConnectionType[connection.type] },
            {
              name: "last_sync",
              value: connection.triggeredTimestampUtc
                ? format(
                    parseISO(connection.triggeredTimestampUtc),
                    "yyyy-MM-dd HH:mm:ss",
                  )
                : "",
            },
          ],
        };
      }),
    [connections],
  );

  const handleCreate = useCallback(
    async (closeDialog?: () => void) => {
      if (formData.length === 0 || !clientId) return;
      await handleTableAction({
        apiCall: () =>
          fetchFortnoxActivationUrl({
            clientId: clientId,
            scopes: formData,
          }),
        closeDialog,
        callback: setCreateResponse,
      });
      setSelected([]);
    },
    [clientId, formData],
  );

  const handleDelete = useCallback(
    async (closeDialog?: () => void) => {
      if (selected.length !== 1) return;
      const connection = selected[0];
      await handleTableAction({
        apiCall: () => deleteClientConnection(String(clientId), connection.id),
        successMessage: t("alert:create_activation_link_success", {
          type: t("connection:connection"),
          name: connection.id,
        }),
        failureMessage: t("alert:delete_fail", {
          type: t("connection:connection"),
          name: connection.id,
        }),
        closeDialog,
        callback: loadConnections,
      });
      setSelected([]);
    },
    [selected, clientId, t, loadConnections],
  );

  const handleSync = useCallback(
    async (closeDialog?: () => void) => {
      if (!connections || !clientId || !syncType) return;
      const payload: FortnoxFetchPayload = {
        ClientId: clientId,
        ConnectionIds:
          selected.length === 0
            ? connections.map((x) => x.id)
            : selected.map((x) => x.id),
        StartYear: year,
        FetchType: syncType,
      };
      const result = await handleTableAction({
        apiCall: () => fortnoxFetch(payload),
        successMessage: t("alert:fortnox_fetch_started"),
        failureMessage: t("alert:fortnox_fetch_failed"),
        closeDialog,
      });

      if (result) setSelected([]);
    },
    [selected, connections, clientId, t, syncType, year],
  );

  const onSelect = useCallback((item: Connection) => {
    const itemId = item.id;
    setSelected((prevState) =>
      prevState.some((selectedItem) => selectedItem.id === itemId)
        ? prevState.filter((selectedItem) => selectedItem.id !== itemId)
        : [...prevState, item],
    );
  }, []);

  const disabledStates = useMemo(
    () => ({
      view:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
          ? t("alert:multiple_items_selected")
          : undefined,

      delete:
        selected.length === 0
          ? t("alert:no_item_selected")
          : selected.length > 1
          ? t("alert:multiple_items_selected")
          : undefined,

      sync:
        selected.length > 1
          ? t("alert:multiple_items_selected")
          : connections.length === 0
          ? t("alert:no_connections_sync")
          : undefined,
    }),
    [selected, t, connections],
  );

  const actions = useMemo(() => {
    if (!clientId) return undefined;
    return {
      create: {
        dialogTitle: t("connection:create_new_connection"),
        dialogContent: (
          <ConnectionForm onChange={setFormData} clientId={clientId} />
        ),
        onCreate: handleCreate,
      },
    };
  }, [handleCreate, clientId, t]);

  const selectedActions = useMemo(() => {
    if (!clientId) return undefined;
    return {
      custom: [
        {
          dialogTitle: t("fortnox:sync_data"),
          dialogContent: (
            <SyncConnectionsDataForm
              onChange={(syncType, syncFromYear) => {
                handleChange(syncType, syncFromYear);
              }}
            />
          ),
          buttonText: selected.length === 0 ? t("fortnox:sync_all") : t("sync"),
          buttonIcon: <DownloadForOffline />,
          buttonVariant: "outlined",
          dialogActionText:
            selected.length === 0 ? t("fortnox:sync_all") : t("sync"),
          disabled: disabledStates.sync,
          onAction: handleSync,
        },
        {
          dialogTitle: t("connection:view_connection"),
          dialogContent: (
            <ConnectionForm initialData={selected[0]} {...{ clientId }} />
          ),
          buttonText: t("view"),
          buttonIcon: <Preview />,
          buttonVariant: "outlined",
          disabled: disabledStates.view,
        },
      ],
      delete: {
        dialogTitle: t("connection:delete_connection"),
        dialogContent: (
          <ConfirmationForm
            itemName={selected[0]?.id}
            itemType={t("connection:connection")}
            additionalWarning={t("cant_undo")}
          />
        ),
        disabled: disabledStates.delete,
        onDelete: handleDelete,
      },
    };
  }, [
    clientId,
    t,
    selected,
    disabledStates.sync,
    disabledStates.view,
    disabledStates.delete,
    handleSync,
    handleDelete,
  ]);

  const onRefetch = useCallback(() => {
    setSelected([]);
    loadConnections();
  }, [loadConnections]);

  return (
    <Box>
      <ActionTable<Connection>
        {...{
          columns,
          data,
          isLoading,
          selected,
          onSelect,
          actions,
          selectedActions,
          onRefetch,
        }}
      />
      {createResponse && (
        <EmailDialog
          createResponse={createResponse}
          onClose={() => setCreateResponse(undefined)}
        />
      )}
    </Box>
  );
};

export default withAuthentication(OrganisationClientConnections);
