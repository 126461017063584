import { Client, ClientFormValues, Connection } from "types";
import { axiosInstance } from "utils";

export const fetchClients = async (organisationId: string) => {
  return await axiosInstance.get<Client[]>(`/clients/${organisationId}`);
};

export const createClient = async (client: ClientFormValues) => {
  return await axiosInstance.post<Client>("/clients", client);
};

export const fetchClientConnections = async (clientId: string) => {
  return await axiosInstance.get<Connection[]>(
    `/clients/${clientId}/connections`
  );
};

export const fetchClientConnection = async (
  clientId: string,
  connectionId: string
) => {
  return await axiosInstance.get<Connection>(
    `/clients/${clientId}/connection/${connectionId}`
  );
};

export const updateClient = async (id: string, client: ClientFormValues) => {
  return await axiosInstance.put<Client>(`/clients/${id}`, client);
};

export const deleteClient = async (id: string) => {
  return await axiosInstance.delete(`/clients/${id}`);
};

export const deleteClientConnection = async (
  clientId: string,
  connectionId: string
) => {
  return await axiosInstance.delete(
    `/clients/${clientId}/connection/${connectionId}`
  );
};
